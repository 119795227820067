<template>
  <div>
    <div id="watchPage">
      <div id="topBar">
        <div class="backHome" @click="goBack">
          <img src="@/assets/images/pk/fanhui.png" alt />
          <div>返回大厅</div>
        </div>
        <div class="roundNum">回合数:{{ boxList.length }}</div>
      </div>
      <div id="boxNum">
        <div class="chooseBox">
          <div>已选物品{{ boxList.length }}</div>
          <div class="totalClass">
            共计:
            <img src="@/assets/images/pk/jinbi.png" alt />
            <span>{{ totalPrice }}</span>
          </div>
        </div>
        <div class="boxShow">
          <div class="boxLine" v-for="(box, index) in boxList" :key="index">
            <img
              :src="box.img"
              :style="[round == index ? { width: '70px', height: '55px' } : {}]"
            />
            <div>{{ box.price }}</div>
          </div>
        </div>
      </div>
      <div id="users">
        <div class="user" v-for="(user, uIndex) in users" :key="uIndex">
          <div class="avatar">
            <img
              v-if="user.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt=""
            />
            <img v-else :src="user.portrait" />
            <div class="userName">
              <span style="color: #fff" class="span1">{{ user.name }}</span>
              <span style="color: #fff000" class="span1">{{
                Math.round(user.AuserTotal * 100) / 100
              }}</span>
            </div>
          </div>
          <div class="gunborder">
            <div class="drawResult">
              <img
                src="@/assets/images/watchPk/victory.png"
                v-show="user.ApkStatus == 1"
              />
              <img
                src="@/assets/images/watchPk/lose.png"
                v-show="user.ApkStatus == 2"
              />
              <img
                src="@/assets/images/watchPk/ping.png"
                v-show="user.ApkStatus == 3"
              />
            </div>
            <ul class="drawIng LB" v-if="ingShow == true">
              <li
                class="skinLine"
                v-for="(skin, index) in user.ALBlist[0]"
                :key="index"
                v-show="skinLineShow == true"
              >
                <img :src="skin.img" />
                <div class="sName" style="color: #fff">{{ skin.name }}</div>
                <div class="skinPrice">
                  <img src="@/assets/images/pk/jinbi.png" />
                  <div style="color: #fff000">{{ skin.openPrice }}</div>
                </div>
              </li>
            </ul>
            <div class="drawShow" v-if="showResult">
              <div
                class="drawShowLine"
                v-for="(skinData, index) in user.AskinList"
                :key="index"
              >
                <img :src="skinData.img" />
                <div class="sName" style="font-size: 12px; color: #fff">
                  {{ skinData.name }}
                </div>
                <div class="drawSkinPrice">
                  <img src="@/assets/images/pk/jinbi.png" />
                  <div style="font-size: 12px; color: #fff000">
                    {{ skinData.openPrice }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="watchPagePC">
      <div id="PCBody">
        <div id="topBar">
          <span @click="goBack">全部房间</span>
          <span @click="createHome">创建房间</span>
          <span @click="goHistory">历史记录</span>
        </div>
        <div id="boxNum">
          <div>已选物品{{ boxList.length }}</div>
          <div class="boxLine" v-for="(box, index) in boxList" :key="index">
            <img :src="box.img" />
            <div class="boxsPrice">
              <img src="@/assets/images/pk/jinbi.png" alt />{{ box.price }}
            </div>
          </div>
        </div>
        <div id="users">
          <div class="user" v-for="(user, uIndex) in users" :key="uIndex">
            <div class="avatar">
              <img
                v-if="user.portrait == null"
                src="@/assets/images/user/head.jpg"
                alt=""
              />
              <img v-else :src="user.portrait" />
            </div>
            <div class="ingParent">
              <ul class="drawIng LB">
                <li
                  class="skinLine"
                  v-for="(skin, index) in user.ALBlist[0]"
                  :key="index"
                  v-show="skinLineShow == true"
                >
                  <img :src="skin.img" />
                  <div class="sName" style="color: #fff">{{ skin.name }}</div>
                  <div class="skinPrice">
                    <img src="@/assets/images/pk/jinbi.png" />
                    <div style="color: #fff000">{{ skin.openPrice }}</div>
                  </div>
                </li>
              </ul>
              <div class="drawResult">
                <img
                  src="@/assets/images/watchPk/victory.png"
                  v-show="user.ApkStatus == 1"
                />
                <img
                  src="@/assets/images/watchPk/lose.png"
                  v-show="user.ApkStatus == 2"
                />
                <img
                  src="@/assets/images/watchPk/ping.png"
                  v-show="user.ApkStatus == 3"
                />
                <span
                  style="
                    color: #fff000;
                    position: absolute;
                    top: 160px;
                    left: 95px;
                  "
                  >{{ Math.round(user.AuserTotal * 100) / 100 }}</span
                >
              </div>
            </div>
            <div class="drawShow" v-if="showResult">
              <div
                class="drawShowLine"
                v-for="(skinData, index) in user.AskinList"
                :key="index"
              >
                <img :src="skinData.img" />
                <div class="sName" style="font-size: 12px; color: #fff">
                  {{ skinData.name }}
                </div>
                <div class="drawSkinPrice">
                  <img src="@/assets/images/pk/jinbi.png" />
                  <div style="font-size: 12px; color: #fff000">
                    {{ skinData.openPrice }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRoomList, getPkResult, boxOrnament } from "../../api/index";
export default {
  data() {
    return {
      id: 0,
      roomType: 0,
      boxList: [],
      totalPrice: 0,
      users: [],
      pkResult: [],
      skinListRandom: [],
      drawShowList: [],
      ingShow: false,
      showResult: false,
      round: 0,
      roundTimer: null,
      roundTimer2: null,
      roundTimer3: null,
      skinLineShow: true,
      endStatus: "",
      audio: null,
      anHeight: 164,
      phoneType: "",
    };
  },
  created() {
    if (/android/i.test(navigator.userAgent)) {
      // console.log("Android");
      this.phoneType = "Android";
      console.log(this.phoneType);
    }
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.phoneType = "ios";
      console.log(this.phoneType);
    }
  },
  async mounted() {
    let device = window.screen.width;
    if (device <= 550) {
      if (this.phoneType == "Android") {
        this.anHeight = 112;
      } else if (this.phoneType == "ios") {
        this.anHeight = 113;
      }
    }

    this.id = this.$route.params.id;
    // console.log("房间号", this.id);
    // 房间信息
    let roomInfo = (await getRoomList(this.id)).data.data;
    // console.log("房间信息", roomInfo);
    this.endStatus = roomInfo.ttFightAgainst.endStatus;
    // 房间模式 3欧皇 4非酋 5组队
    this.roomType = roomInfo.ttFightAgainst.type;
    // 箱子数组
    this.boxList = roomInfo.boxList;
    // console.log("箱子数组", this.boxList);
    // 玩家数组
    this.users = roomInfo.users;
    // console.log("玩家数组", this.users);
    for (let u in this.users) {
      this.users[u].ALBlist = [];
      this.users[u].AskinList = [];
      this.users[u].AuserTotal = 0;
    }
    // 共计价格
    this.totalPrice = roomInfo.ttFightAgainst.payCoin;
    // 对战结果 1赢 2输 3平
    this.pkResult = (await getPkResult(this.id)).data.rows;
    // console.log("对战结果", this.pkResult);

    for (let i in this.boxList) {
      for (let u in this.users) {
        let skinInfo = (await boxOrnament(this.boxList[i].bId)).data.rows;
        this.users[u].ALBlist.push(this.shuffle(skinInfo));
      }
    }

    let index = 0;
    for (let u in this.users) {
      for (let k in this.users[u].ALBlist) {
        this.users[u].ALBlist[k].push(this.pkResult[index]);
        index++;
      }
    }

    setTimeout(() => {
      this.ingShow = true;
      this.audio = document.getElementById("audio");
      this.audio.play();
    }, 200);
    this.roundTimer3 = setInterval(() => {
      var styleStr = `
          @keyframes openboxes {
            0% {
              transform: translateY(0px);
            }
            100% {
              transform: translateY(-${
                this.anHeight * (this.users[0].ALBlist[0].length - 1)
              }px);
            }
          }
        `;
      let style = document.createElement("style");
      style.innerHTML = styleStr;
      document.querySelector(".LB").appendChild(style);
    }, 200);
    this.roundTimer2 = setInterval(() => {
      for (let u in this.users) {
        this.users[u].AskinList.unshift(
          this.users[u].ALBlist[0][this.users[u].ALBlist[0].length - 1]
        );
        this.users[u].AuserTotal +=
          this.users[u].ALBlist[0][
            this.users[u].ALBlist[0].length - 1
          ].openPrice;
      }
      this.showResult = true;
    }, 6000);

    this.roundTimer = setInterval(() => {
      this.round++;
      if (this.round == this.boxList.length) {
        this.audio.pause();
        clearInterval(this.roundTimer);
        clearInterval(this.roundTimer2);
        clearInterval(this.roundTimer3);
        setTimeout(() => {
          this.ingShow = false;
          this.pkResultFunc(this.pkResult);
        }, 200);
      }
      this.skinLineShow = false;
      for (let u in this.users) {
        this.users[u].ALBlist.shift();
      }
      setTimeout(() => {
        this.skinLineShow = true;
        for (let u in this.users) {
          if (this.users[u].ALBlist.length > 0) {
            this.audio.play();
          }
        }
      }, 200);
    }, 6000);

    // 输赢展示
    // this.pkResultFunc(this.pkResult);
  },
  destroyed() {
    this.audio.pause();
    clearInterval(this.roundTimer);
    clearInterval(this.roundTimer2);
    clearInterval(this.roundTimer3);
  },
  methods: {
    shuffle(list) {
      return list.sort(() => 0.5 - Math.random());
    },
    pkResultFunc(pkResult) {
      let resultPriceList = [];
      for (let i in this.users) {
        let userTotalPrice = 0;
        for (let j in pkResult) {
          if (pkResult[j].uid == this.users[i].id) {
            userTotalPrice += pkResult[j].openPrice;
          }
        }
        resultPriceList.push(Number(userTotalPrice.toFixed(2)));
        this.users[i].AllSkinPrice = Number(userTotalPrice.toFixed(2));
      }
      if (this.roomType == 3) {
        resultPriceList = resultPriceList.sort((a, b) => {
          return b - a;
        });
      }
      if (this.roomType == 4) {
        resultPriceList = resultPriceList.sort((a, b) => {
          return a - b;
        });
      }
      resultPriceList = Array.from(new Set(resultPriceList));
      for (let i in this.users) {
        if (this.users[i].AllSkinPrice == resultPriceList[0]) {
          this.users[i].ApkStatus = 1;
        } else {
          this.users[i].ApkStatus = 2;
        }
        if (resultPriceList.length == 1) {
          this.users[i].ApkStatus = 3;
        }
      }
      let allUserPrice = 0;
      for (let u in this.users) {
        allUserPrice += this.users[u].AuserTotal;
        if (this.users[u].ApkStatus == 2) {
          this.users[u].AuserTotal = 0;
        }
      }
      for (let u in this.users) {
        if (this.users[u].ApkStatus == 1) {
          this.users[u].AuserTotal = allUserPrice;
        }
      }
    },
    goBack() {
      this.$router.push({ name: "Pk" });
    },
    createHome() {
      this.$router.push({ name: "create" });
    },
    goHistory() {
      this.$router.push({ name: "pklog" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/watchPage.css";
@import "../../assets/css/watchPagePC.css";
</style>
